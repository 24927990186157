import { useEffect, useState } from "react";
import { createPaymentIntentAPICall } from "../../../modules/dashboard/dashboardService/empowermentUnstoppable";
import { getUserData } from "../../js/userCredential";
import CustomButton from "../customButton/customButton";
import LoadStripe from "../loadStripe/loadStripe";
import Popup from "../popup/popup";
import Style from "./bottomBuyPopup.module.scss";

const BottomBuyPopup = ({
  currentSessionId,
  selectedSession,
  isMaintenance,
  discountPrice,
  currentSession,
  price,
}) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [stripeOption, setStripeOption] = useState({});
  const [amount, setAmount] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [isMobileTab, setIsMobileTab] = useState(false);

  useEffect(async () => {
    let _userData = await getUserData();
    setCouponId(_userData.couponId);
  }, []);

  useEffect(() => {
    if (Object.keys(stripeOption).length > 0) {
      setIsShowPopup(true);
    }
  }, [stripeOption]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize());
  }, []);

  const handleResize = () => {
    setIsMobileTab(window.innerWidth <= 1000 && window.innerWidth >= 600);
  };

  const handleButtonClick = async () => {
    setIsButtonLoading(true);
    let _response = await createPaymentIntentAPICall(currentSessionId);
    setIsButtonLoading(false);
    if (_response.isSuccess) {
      let _option = {
        clientSecret: _response.clientSecret,
      };
      setAmount(_response.amount);
      setStripeOption({ ..._option });
    }
  };

  const handleClosePopup = () => {
    setIsShowPopup(false);
  };

  return (
    <>
      <div className="w-100">
        <div
          // onClick={task?.isBuyButton ? "" : handleClick}
          className={`d-flex text-center flex-column align-items-center cursor-pointer ${Style.container}`}
        >
          <p className={`${Style.title}`}>
            For best results complete your session min every 3 days or max 7 days. 
            {/* {isMaintenance
              ? "Maintenance session"
              : `Empowerment session ${currentSession} `}
            audio will be available in 3 days, once session is bought. */}
          </p>
        </div>
        {/* <div className="d-flex justify-content-center">
          <CustomButton
            title={
              isMaintenance ? (
                <>
                  Buy Maintenance Session "
                  {(price !== discountPrice) ? (
                    <>
                      <del>$ {price} AUD</del>&nbsp; $ {discountPrice} AUD
                    </>
                  ) : (
                    <>$ {price} AUD</>
                  )}
                  "
                </>
              ) : (
                <span className="text-dark mt-1" style={{ fontSize: 14 }}>
                  Buy Session {selectedSession} "
                  {(price !== discountPrice) ? (
                    <>
                      <del>$ {price} AUD</del>&nbsp;  $ {discountPrice} AUD
                    </>
                  ) : (
                    <>$ {price} AUD</>
                  )}
                  "
                  {price !== discountPrice && (
                    <div className="text-dark" style={{ fontSize: 10 }}>
                        50% Discount Applied
                    </div>
                  )}
                </span>
              )
            }
            isLoading={isButtonLoading}
            disabled={isButtonLoading}
            handleButtonClick={handleButtonClick}
          />
        </div> */}
        <div className="d-flex justify-content-center">
          <div
                onClick={handleButtonClick}
                className="d-flex justify-content-center bg-light rounded-pill cursor-pointer mt-3"
                style={{width: 350}}
              >
                  {isMaintenance ? (
                    <>
                    <div className="my-2">
                    <span className="text-dark" style={{ fontSize: isMobileTab ? 10: 13, marginBottom: 0 }}>Buy Maintenance Session </span>
                    {price !== discountPrice && (
                      <div className="text-dark" style={{ fontSize: isMobileTab ? 8 : 10 }}>
                          50% Discount Applied
                      </div>)}
                    </div>
                    {(price !== discountPrice) ? (
                      <>
                        <div className="d-block" style={{ marginLeft: isMobileTab ? 10 : 30 }}>
                          <span className="text-dark d-block" style={{ fontSize: isMobileTab ? 10: 13, marginBottom: 0, marginTop: 15 }}>${discountPrice} AUD</span>
                          <div style={{ fontSize: isMobileTab? 8 : 10}}><del >$ {price} AUD</del> </div>
                        </div>
                      </>
                    ) : (
                      <>${price}</>
                    )}
                  </>
                ):(
                    <><div className="my-2">
                    <span className="text-dark" style={{ fontSize: isMobileTab ? 12: 15, marginBottom: 0 }}>Buy Session {selectedSession} </span>
                    {price !== discountPrice && (
                      <div className="text-dark" style={{ fontSize: isMobileTab ? 9 : 12 }}>
                          50% Discount Applied
                      </div>)}
                  </div>
                  {(price !== discountPrice) ? (
                        <span className="d-flex justify-content-center flex-column text-center" style={{ marginLeft: isMobileTab ? 10 : 30 }}>
                          <span className="text-dark d-block " style={{ fontSize: isMobileTab ? 10: 13, marginBottom: 0 }}>${discountPrice} AUD</span>
                          <div style={{ fontSize: isMobileTab? 8 : 10}}><del>$ {price} AUD</del> </div>
                        </span>
                      ) : (
                        <>$ {price} AUD</>
                      )}
                      </>)}
          </div>
        </div>
      </div>
      {isShowPopup && (
        <Popup isOpen={true} handleClose={handleClosePopup} title={"Checkout"}>
          <LoadStripe
            isConfirmPayment={true}
            buttonLoading={false}
            options={stripeOption}
            amount={amount}
            price={price}
          />
        </Popup>
      )}
    </>
  );
};
export default BottomBuyPopup;
