import Style from "../../../../shared/components/empowermentAndUnstoppablePopup/empowermentAndUnstoppablePopup.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../shared/components/customButton/customButton";
import { useEffect, useState } from "react";
import { getUserData } from "../../../../shared/js/userCredential";

const UnstoppableSuccessfullPopup = ({
  title,
  selectedSession,
  isEmopwerment,
  currentSession,
  price,
  discountPrice,
  showButton = false,
  handlePopup,
  isMaintenance,
  month,
  handleBuyButton,
  isButtonLoading = false,
  isNeextSessionPaid,
}) => {
  const [couponId, setCouponId] = useState(null);
  const [isMobileTab, setIsMobileTab] = useState(false);
  const [isIpadTab, setIsIpadTab] = useState(false);

  useEffect(async () => {
    let _userData = await getUserData();
    setCouponId(_userData.couponId);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize());
  }, []);
  const handleButtonClick = () => {
    handlePopup?.(true);
  };

  const handlePurchaseButton = () => {
    handleBuyButton?.();
  };
  const handleResize = () => {
    setIsMobileTab(window.innerWidth <= 525);
    setIsIpadTab(window.innerWidth >= 992 && window.innerWidth <= 1288);
  };

  return (
    <div className={`${Style.popup} mt-3 pb-5`}>
      {
        <div
          onClick={handleButtonClick}
          className={`d-flex justify-content-end cursor-pointer ${Style.cross}`}
        >
          <CloseIcon />
        </div>
      }
      <div
        className={`d-flex flex-column align-items-center text-center  ${Style.content}`}
      >
        <h5 className="mb-5">
          Successfully Completed
          {!isMaintenance && ` Unstoppable you program ${title}`}
          {isMaintenance && "  Unstoppable you program Maitenance Session"}
          {month > 0 && ` Month ${month}`}
        </h5>
        {!isMaintenance ? (
          <>
            <p className="text-justify">
              Congratulations on completing your Unstoppable You session.
              Complete the lessons, tips and activities at a time you are
              mentally alert every day.
            </p>
            <p className="text-justify">
              Don’t forget to listen to each pre-session audio at least 2 hours
              before each session. Maybe listen to it in the morning in a
              darkened room without distractions. Then listen to your session at
              night while going to sleep. Close your eyes and listen until the
              music ends.
            </p>
          </>
        ) : (
          <>
            <p className="text-justify">
              Congratulations on completing your Unstoppable You maintenance
              session. Don’t forget to listen to the lessons and tips, and
              complete the activities. Write in your journal daily and take your
              brain on a date.
            </p>
            <p className="text-justify">
              Use your Boost membership nightly. Choose the audios that interest
              you or best suit your needs. Add them to your playlist. Play them
              during the day while resting or at night while sleeping. Keep your
              eyes closed until the music ends.
            </p>
            <p className="text-justify">
              Reach out to us if you have any questions or need anything.
            </p>
          </>
        )}
        {currentSession === 0 && !isNeextSessionPaid && (
          <div className="mt-5">
            {/* <CustomButton
              title={
                <>
                  Buy Unstoppable You "
                  {(price !== discountPrice) ? (
                    <>
                      <del>$ {price} AUD</del>&nbsp; $ {discountPrice} AUD
                    </>
                  ) : (
                    <>$ {price} AUD</>
                  )}
                  "
                </>
              }
              disabled={isButtonLoading}
              isLoading={isButtonLoading}
              buttonStyle={"white-btn cursor-pointer w-100 mt-1"}
              handleButtonClick={handlePurchaseButton}
            /> */}
            <div
                onClick={handleButtonClick}
                className="d-flex justify-content-center bg-light rounded-pill cursor-pointer px-3"
                // style={{width: 300}}
              >
                  <div className="my-2">
                    <p className="text-dark" style={{ fontSize: (isMobileTab ? 10: (isIpadTab ? 11 : 15)), marginBottom: 0, marginLeft: 50, marginRight: 50 }} >Next </p>
                    {/* <p className="text-dark" style={{ fontSize: (isMobileTab ? 10: (isIpadTab ? 11 : 15)), marginBottom: 0 }} >Buy Unstoppable You </p>
                    {price !== discountPrice && (
                      <div className="text-dark" style={{ fontSize: (isMobileTab ? 8 : (isIpadTab ? 8 : 10)) }}>
                          50% Discount Applied
                      </div>)} */}
                  </div>
                  {/* {(price !== discountPrice) ? (
                        <span className="d-flex justify-content-center flex-column text-center" style={{ marginLeft: (isMobileTab ? 10 :(isIpadTab ? 15 : 40)) }}>
                          <p className="text-dark d-block " style={{ fontSize: (isMobileTab ? 10: (isIpadTab ? 11 : 15)), marginBottom: 0  }}>${discountPrice} AUD</p>
                          <del style={{ fontSize: (isMobileTab ? 9 : (isIpadTab ? 10 : 12)), marginTop: -3, position: 'relative'}} >$ {price} AUD</del> 
                        </span>
                      ) : (
                        <>$ {price} AUD</>
                      )} */}
              </div>
          </div>
        )}
        {currentSession !== 0 && !isMaintenance && showButton && (
          <div className="mt-5">
            {/* {selectedSession === 0 && (
              <span className={`${Style.aboveText}`}>
                {task?.button.aboveText}
              </span>
            )} */}
            <button
              onClick={handleButtonClick}
              className="white-btn cursor-pointer w-100 mt-1"
            >
              {currentSession === 10 && "Booster Pre-Session Audio"}
              {currentSession >= 11 && "Pre-Session Maintenance"}

              {currentSession > 0 && currentSession < 10 && (
                <>Pre-Session {currentSession + 1} Audio</>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default UnstoppableSuccessfullPopup;
