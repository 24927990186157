import { useEffect, useState } from "react";
import LoadStripe from "../../../../shared/components/loadStripe/loadStripe";
import Popup from "../../../../shared/components/popup/popup";
import Style from "./paidSessionPopup.module.scss";
import { createPaymentIntentAPICall } from "../../dashboardService/empowermentUnstoppable.js";
import CustomButton from "../../../../shared/components/customButton/customButton";
import { getUserData } from "../../../../shared/js/userCredential";

const PaidSessionPopup = ({
  isEmopwerment,
  isMaintenance,
  currentSession,
  currentSessionId,
  discountPrice,
  price,
}) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [stripeOption, setStripeOption] = useState({});
  const [amount, setAmount] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [isMobileTab, setIsMobileTab] = useState(false);

  useEffect(async () => {
    let _userData = await getUserData();
    setCouponId(_userData.couponId);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize());
  }, []);

  useEffect(() => {
    if (Object.keys(stripeOption).length > 0) {
      setIsShowPopup(true);
    }
  }, [stripeOption]);
  const handleResize = () => {
    setIsMobileTab(window.innerWidth <= 525);
  };

  const handleButtonClick = async () => {
    setIsButtonLoading(true);
    let _response = await createPaymentIntentAPICall(currentSessionId);
    setIsButtonLoading(false);
    if (_response.isSuccess) {
      let _option = {
        clientSecret: _response.clientSecret,
      };
      setAmount(_response.amount);
      setStripeOption({ ..._option });
    }
  };

  const handleClosePopup = () => {
    setIsShowPopup(false);
  };

  return (
    <>
      <div className={`${Style.popup} mt-3 pb-5`}>
        <div
          className={`d-flex flex-column align-items-center text-center  ${Style.content}`}
        >
          <h5 className="mb-5 mt-2">
            Successfully Completed Unstoppable you
            {currentSession === 1
              ? " Introductory Session"
              : ` Session ${currentSession}`}
          </h5>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            ndustry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s,
          </p>
          <div className="mt-5 w-100">
            {/* <CustomButton
              title={
                isEmopwerment && isMaintenance ? (
                  <>
                    Buy Maintenance Session "
                    {(price !== discountPrice) ? (
                      <>
                        <del>${price}</del>&nbsp; ${discountPrice}
                      </>
                    ) : (
                      <>${price}</>
                    )}
                  </>
                ) : (
                  <span className=" mt-1 d-flex" style={{ fontSize: 14 }}>
                    <div>
                      <span className="text-dark" >Buy Unstoppable You </span>
                      {price !== discountPrice && (
                        <div className="text-dark" style={{ fontSize: 10 }}>
                            50% Discount Applied
                        </div>)}
                    </div>
                    {(price !== discountPrice) ? (
                      <span className="d-block">
                        <span className="text-dark d-block mx-5">${discountPrice} AUD</span>
                        <del style={{ fontSize: 12, marginTop: -3, position: 'relative'}} >$ {price} AUD</del> 
                      </span>
                    ) : (
                      <>$ {price} AUD</>
                    )}
                    "
                  </span>
                )
              }
              isLoading={isButtonLoading}
              disabled={isButtonLoading}
              handleButtonClick={handleButtonClick}
              buttonStyle={Style.whiteBtn}
            /> */}
            <div className="d-flex justify-content-center">
              <div
                onClick={handleButtonClick}
                className="d-flex justify-content-center bg-light rounded-pill cursor-pointer"
                style={{width: 350}}
              >
                  {isEmopwerment && isMaintenance ? (
                    <>
                    <div className="my-2">
                    <p className="text-dark" style={{ fontSize: isMobileTab ? 9: 13, marginBottom: 0 }}>Buy Maintenance Session </p>
                    {price !== discountPrice && (
                      <div className="text-dark" style={{ fontSize: isMobileTab ? 8 : 10 }}>
                          50% Discount Applied
                      </div>)}
                    </div>
                    {(price !== discountPrice) ? (
                      <span className="d-flex justify-content-center flex-column text-center" style={{ marginLeft: isMobileTab ? 10 : 30 }}>
                        <p className="text-dark d-block " style={{ fontSize: isMobileTab ? 9: 13, marginBottom: 0  }}>${discountPrice} AUD</p>
                        <del style={{ fontSize: isMobileTab? 8 : 10, marginTop: -3, position: 'relative'}} >$ {price} AUD</del> 
                      </span>
                    ) : (
                      <>${price}</>
                    )}
                  </>
                ):(
                    <><div className="my-2">
                    <p className="text-dark" style={{ fontSize: isMobileTab ? 10: 15, marginBottom: 0 }} >Buy Unstoppable You </p>
                    {price !== discountPrice && (
                      <div className="text-dark" style={{ fontSize: isMobileTab ? 8 : 10 }}>
                          50% Discount Applied
                      </div>)}
                  </div>
                  {(price !== discountPrice) ? (
                        <span className="d-flex justify-content-center flex-column text-center" style={{ marginLeft: isMobileTab ? 10 : 40 }}>
                          <p className="text-dark d-block " style={{ fontSize: isMobileTab ? 10: 15, marginBottom: 0  }}>${discountPrice} AUD</p>
                          <del style={{ fontSize: isMobileTab? 9 : 12, marginTop: -3, position: 'relative'}} >$ {price} AUD</del> 
                        </span>
                      ) : (
                        <span className="d-flex justify-content-center flex-column text-center" style={{ marginLeft: isMobileTab ? 10 : 40 }}>
                          <p className="text-dark d-block " style={{ fontSize: isMobileTab ? 10: 15, marginBottom: 0  }}>${price} AUD</p>
                        </span>
                      )}
                      </>)}
              </div>
            </div>
            {/* <button
              onClick={handleButtonClick}
              className="white-btn cursor-pointer w-100 mt-1"
            >{`Buy Session  ${currentSession + 1} `}</button> */}
          </div>
        </div>
      </div>

      {isShowPopup && (
        <Popup isOpen={true} handleClose={handleClosePopup} title={"Checkout"}>
          <LoadStripe
            amount={amount}
            price={price}
            isConfirmPayment={true}
            buttonLoading={isButtonLoading}
            options={stripeOption}
          />
        </Popup>
      )}
    </>
  );
};
export default PaidSessionPopup;
