import { Box, Container } from "@mui/material";
import "react-intl-tel-input/dist/main.css";
import Style from "./promoCodeForm.module.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../shared/components/customButton/customButton";
import InputField from "../../../../shared/components/inputField/inputField";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import {
  setPromoCodeAPICall,
  updateEmailAPICall,
} from "../../authService/authService";
import { getUserData } from "../../../../shared/js/userCredential";
import { UserModel } from "../../model/userModel";
import { UserStatus } from "../../../../shared/js/enums";
import { getUserActiveStatus } from "../../../../shared/js/getUserActiveStatus";
import { webFlowRoutes } from "../../../../shared/js/webFlowRoutes";
import { addUpdateUser } from "../../../chat/services/chat";
import { async } from "@firebase/util";

const PromoCodeForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const emailVerificationForm1 = FormBuilder.group({
    PromoCode: ["", Validators.required],
  });

  const handleNextPageNavigation = async () => {
    let _status = getUserActiveStatus();
    let _redirectUrl = webFlowRoutes.find(
      (routeObj) => routeObj.status === _status
    )?.route;
    navigate(_redirectUrl || "/");
  };
  const search = useLocation().search;
  const handleSubmit = async (e, formValue) => {
    e.preventDefault();
    let _promoCode = formValue.PromoCode;
    if (_promoCode === "") {
      return;
    }
    setIsLoading(true);
    let _isSuccess = await setPromoCode(_promoCode);
    setIsLoading(false);
    if (_isSuccess) {
      let _userData = await getUserData();
      const { status } = _userData;
      status === UserStatus.email
        ? navigate("/email-verification")
        : handleNextPageNavigation();
    }
  };
  const handleSkipButton = async () => {
    
    setIsSkipLoading(true);
    let _isSuccess = await setPromoCode("");
    setIsSkipLoading(false);
    if (_isSuccess) {
      let _userData = await getUserData();
      const { status } = _userData;
      status === UserStatus.email
        ? navigate("/email-verification")
        : handleNextPageNavigation();
    }
  };

  const setPromoCode = async (promoCode) => {
    return await setPromoCodeAPICall(promoCode);
  };

  return (
    <div>
      <h3 class={`text-white text-center mb-1 ${Style.mainHeading}`}>
        Step towards your Happiness
      </h3>
      <Container className={`${Style.signupBox} `}>
        <h4 class={`text-white mt-3 ${Style.title}`}>Promo Code</h4>
        <p
          class={`text-white text-center mx-auto ${Style.description} ${Style.fieldWidth}`}
        >
          Please enter promo code if you have
        </p>
        <FieldGroup
          control={emailVerificationForm1}
          render={({ get, invalid, disabled, value }) => (
            <form onSubmit={(e) => handleSubmit(e, value)}>
              <label
                class={`text-white  mb-0  mt-2 text-start ${Style.fieldWidth} ${Style.labelText}`}
              >
                Promo Code
              </label>
              <div id="recaptcha_container"></div>
              <Container
                class={`d-flex py-3 justify-content-between ${Style.otpFieldBox}`}
              >
                <FieldControl
                  name="PromoCode"
                  render={TextInput}
                  meta={{
                    label: "Promo Code",
                    placeholder: "0000022344859",
                    inputStyle: Style.emailInput,
                    containerStyle: Style.emailContainer,
                  }}
                />
              </Container>
              <Box
                sx={{ display: "flex" }}
                className={`mx-auto text-white justify-content-between ${Style.fieldWidth} ${Style.resendTextBox}`}
              >
                <div className="cursor-pointer">
                  {/* <img src={chatIcon} className={`${Style.chatIcon}`} /> */}
                  <span className={`${Style.labelText} m-2`}></span>
                </div>
                <span className={`${Style.labelText}`}></span>
              </Box>
              <p class={`text-white mt-2 mb-1 ${Style.second}`}></p>
              <CustomButton
                type="submit"
                title="Continue"
                buttonStyle={Style.submitButton}
                disabled={invalid}
                isLoading={isLoading}
              />
              <CustomButton
                type="button"
                title="Skip"
                handleButtonClick={handleSkipButton}
                buttonStyle={`${Style.submitButton} ${Style.skip}`}
                disabled={false}
                isLoading={isSkipLoading}
              />
            </form>
          )}
        />
      </Container>
    </div>
  );
};

const TextInput = ({ handler, touched, hasError, meta }) => (
  <div className="w-100">
    <InputField
      placeholder={`${meta.placeholder}`}
      error={touched && hasError("required") && `${meta.label} is required`}
      handler={handler}
      type={meta.type}
      inputStyle={meta.inputStyle}
      containerStyle={meta.containerStyle}
    />
    <span className={Style.inputError}>
      {touched && hasError("required") && `${meta.label} is required`}
    </span>
  </div>
);

export default PromoCodeForm;
