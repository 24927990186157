import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import betterEnv from "./env.json"

/*------------- firebase object production ---------------------*/
const firebaseConfig = { ...betterEnv.firebase.config };
/* ------------------------------------------------------------*/

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
