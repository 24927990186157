import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAudiosCount, addPreviousMusicList, playMusic } from "../../../redux/Action/wavesurfer";
import { Spinner } from "../../../shared/components/spinner/spinner";
import { playListCategoryEnum } from "../../../shared/js/enums";
import AudioPlayerCard from "../boost/audioPlayerCard/audioPlayerCard";
import {
  getPopularAudiosAPICall,
  getSingalCategoryAudiosAPICall,
  getSuggestedPlaylistAudiosAPICall,
  getYourPlaylistAudioAPICall,
} from "../dashboardService/boost";
import Style from "./audioList.module.scss";

const pageSize = 20;

const AudioList = ({ playlistId, playListIndicator, searchText }) => {
  const [audioList, setAudioList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const musicList = useSelector((state) => state.musicList);
  const PreviousMusicList = useSelector((state) => state.PreviousMusicList);
  const previousMusicIndex = useSelector((state) => state.previousMusicIndex);
  const inActivePlayerIndex = useSelector((state) => state.inActivePlayerIndex);
  const audiosCount = useSelector((state) => state.audiosCount);
  const musicIndex = useSelector((state) => state.musicIndex);
  const dispatch = useDispatch();

  useEffect(async () => {
    let _flag = paginationOffset === 0 ? true : totalRecord > audioList.length;
    if (_flag) {
      if (playListIndicator === playListCategoryEnum.yourPlaylist) {
        await getYourPlaylistAudios(pageSize, paginationOffset, searchText);
      } else if (playListIndicator === playListCategoryEnum.categoryAudios) {
        await getCategoriesAudio(pageSize, paginationOffset, searchText);
      } else if (playListIndicator === playListCategoryEnum.popularAudios) {
        await getPopularAudios(pageSize, paginationOffset, searchText);
      } else if (playListIndicator === playListCategoryEnum.suggestedPlayList) {
        await getSuggestedPlayListAudios(pageSize, paginationOffset, searchText);
      }
    }
  }, [playListIndicator, paginationOffset, searchText]);

  useEffect(async () => {
    if (playListIndicator === playListCategoryEnum.yourPlaylist) {
      await getYourPlaylistAudios(pageSize, paginationOffset, searchText);
      // dispatch(addAudiosCount(Math.random()));
    }
  }, [audiosCount]);

  const getPopularAudios = async (limit, offset, searchField) => {
    setIsLoading(true);
    let _response = await getPopularAudiosAPICall(limit, offset, searchField);
    setIsLoading(false);
    if (_response.isSuccess) {
      let _audioList =
        offset === 0
          ? [..._response.popularAudiolist]
          : [...audioList, ..._response.popularAudiolist];

      // setAudioList(_audioList);
      setAudioList(
        musicList.activePlayerIndex === playlistId
          ? musicList.activePlaylistArray
          : _audioList
      );
      setTotalRecord(_response.count);
    }
  };

  const getYourPlaylistAudios = async (limit, offset, searchField) => {
    setIsLoading(true);
    let _response = await getYourPlaylistAudioAPICall(
      limit,
      offset,
      playlistId,
      searchField
    );
    setIsLoading(false);
    if (_response.isSuccess) {
      let _audioList =
        offset === 0
          ? [..._response.audioPlaylist]
          : [...audioList, ..._response.audioPlaylist];

      // setAudioList(_audioList);
      let _activeAudioObj = musicList.activePlaylistArray.find(item => item.isPlay=== true)

      _audioList.map((item,i)=>{
        if(item.id === _activeAudioObj?.id){
          return(
            _audioList[i].isPlay=true
          )
        }
      })
      setAudioList(_audioList);
      setTotalRecord(_response.count);
      dispatch(addPreviousMusicList(_audioList));
    }
  };
  const getSuggestedPlayListAudios = async (limit, offset, searchField) => {
    setIsLoading(true);
    let _result = await getSuggestedPlaylistAudiosAPICall(
      limit,
      offset,
      searchField
    );
    setIsLoading(false);
    if (_result.isSuccess) {
      let _audioList =
        offset === 0
          ? [..._result.suggestedPlaylist]
          : [...audioList, ..._result.suggestedPlaylist];
      // setAudioList([..._audioList]);
      let _activeAudioObj = musicList.activePlaylistArray.find(item => item.isPlay=== true)

      _audioList.map((item,i)=>{
        if(item.id === _activeAudioObj?.id){
          return(
            _audioList[i].isPlay=true
          )
        }
      })

      setAudioList(
        // musicList.activePlayerIndex === playlistId
        //   ? musicList.activePlaylistArray
        //   : 
          _audioList
      );
      setTotalRecord(_result.count);
      dispatch(addPreviousMusicList(_audioList));

      // if(musicIndex !== inActivePlayerIndex){
      //   dispatch(
      //     playMusic({
      //       musicList: _audioList,
      //       index: musicIndex,
      //       activePlayerIndex: inActivePlayerIndex,
      //     })
      //   );
      // }
    }
  };

  const getCategoriesAudio = async (limit, offset, searchField = "") => {
    setIsLoading(true);
    let _response = await getSingalCategoryAudiosAPICall(
      playlistId,
      offset,
      limit,
      searchField
    );
    setIsLoading(false);
    if (_response.isSuccess) {
      let _audioList =
        offset === 0
          ? [..._response.categoryAudiolist]
          : [...audioList, ..._response.categoryAudiolist];
      // setAudioList([..._audioList]);

      let _activeAudioObj = musicList.activePlaylistArray.find(item => item.isPlay=== true)

      _audioList.map((item,i)=>{
        if(item.id === _activeAudioObj?.id){
          return(
            _audioList[i].isPlay=true
          )
        }
      })
      setAudioList(_audioList);
      setTotalRecord(_response.count);
      dispatch(addPreviousMusicList(_audioList));
    }
  };
  const scrollListner = (e) => {
    const scrolled =
      e.target.clientHeight + e.target.scrollTop + 10 > e.target.scrollHeight;
    if (scrolled) {
      if (!isLoading && totalRecord > audioList.length) {
        setPaginationOffset((prev) => ++prev);
      }
    }
  };

  return (
    <>
      <div className={`mt-5 ${Style.container}`} onScroll={scrollListner}>
        {audioList.length ? (
          <div className={`d-flex flex-wrap`}>
            {audioList.map((item, i) => (
              <div key={i} className={`${Style.playListCard} mb-3`}>
                <AudioPlayerCard
                  musicList={audioList}
                  PreviousMusicList={PreviousMusicList}
                  inActivePlayerIndex={inActivePlayerIndex}
                  activePlayerIndex={musicList.activePlayerIndex}
                  previousIndex={previousMusicIndex}
                  index={i}
                  myPlayerIndex={playlistId}
                  title={item.title}
                  isPlay={item.isPlay}
                  isLock={item.isLock}
                  image={item.image}
                  veritcal={false}
                  isPlaylist={false}
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            {!isLoading && (
              <div className={Style.emptyList}>
                <p>No Audio is Available in the Playlist</p>
              </div>
            )}
          </>
        )}
        {isLoading && (
          <div className={Style.spiner}>
            <Spinner isWhite />
          </div>
        )}
      </div>
    </>
  );
};
export default AudioList;
