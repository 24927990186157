import Style from "../wavesurfer.module.scss";
import LeftPortion from "./LeftPortion";
import { ReactComponent as Rewind } from "../../../../assets/images/audioPlayer/previous.svg";
import { ReactComponent as Previous } from "../../../../assets/images/audioPlayer/rewind.svg";
import { ReactComponent as Pause } from "../../../../assets/images/audioPlayer/pause.svg";
import { ReactComponent as Play } from "../../../../assets/images/audioPlayer/play.svg";
import { ReactComponent as Next } from "../../../../assets/images/audioPlayer/next.svg";
import { ReactComponent as Forward } from "../../../../assets/images/audioPlayer/forward.svg";
import { ReactComponent as Speaker } from "../../../../assets/images/audioPlayer/speaker.svg";
import { ReactComponent as Loop } from "../../../../assets/images/audioPlayer/loop.svg";
import { ReactComponent as Playlist } from "../../../../assets/images/audioPlayer/playlist.svg";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";

const AnyDevice = ({
  track,
  isPlaying,
  isLoading,
  currentTime,
  favoriteAndNewPlaylistHandler,
  previousHandler,
  rewindHandler,
  playPauseHandler,
  forwardHandler,
  nextHandler,
  loopHandler,
  canPrevious,
  canNext,
  volumeHandler,
  trackDuration,
  setPlayerDOMReady,
  isPlayerInstantiated
}) => {
  const [volume, setVolume] = useState(1)
  const [previousVolume, setPreviousVolume] = useState(volume)
  const [isLoopTrack, setIsLoopTrack] = useState(false)
  const [isMuted, setIsMuted] = useState(false)

  useEffect(() => {
    console.debug("Player DOM is ready");
    setPlayerDOMReady(true)
  }, []);

  useEffect(() => {
    if (isPlayerInstantiated) {
      volumeHandler(volume);
    }
  }, [volume, isPlayerInstantiated])

  const inputVolumeHandler = (event) => {
    let newVolume = event.target.value;

    if (newVolume == 0) {
      setPreviousVolume(0);
      setIsMuted(true);
    }
    else {
      // So we don't waste cycles updating a value to the same value
      if (isMuted) {
        setIsMuted(false);
      }
    }

    setVolume(newVolume);
  }

  const loopToggleHandler = () => {
    const newIsloopTrack = !isLoopTrack;
    
    setIsLoopTrack(newIsloopTrack);
    loopHandler(newIsloopTrack);
  }

  const muteToggleHandler = () => {
    let newVolume;

    if (isMuted) {
      newVolume = previousVolume;
      setIsMuted(false);
    }
    else {
      newVolume = 0;
      setPreviousVolume(volume);
      setIsMuted(true);
    }

    setVolume(newVolume);
  }

  return (
    <>
      <LeftPortion
        track={track}
        handlePopup={favoriteAndNewPlaylistHandler}
      />
      <div className={`d-flex flex-column ${Style.middlePortion}`}>
        <div className={`d-flex ${Style.buttonControlBox}`}>
          <div className="d-flex justify-content-center">
            <Box sx={{ display: { xs: 'none', md: 'block' } }} onClick={rewindHandler}>
              <Rewind className={`${Style.controlBtn} me-4`} />
            </Box>
            <Box onClick={() => canPrevious ? previousHandler() : null} className="mx-2">
              <Previous className={Style.controlBtn} style={{ opacity: canPrevious ? 1 : 0.5 }} />
            </Box>
            <Box className={`mx-2 ${ !isPlaying ? "cursor-pointer" : "" }`}>
              { 
                isPlaying ?
                <Pause onClick={playPauseHandler} className={Style.playPause} />
                : <Play onClick={playPauseHandler} className={Style.playPause} />
              }
            </Box>
            <Box onClick={() => canNext ? nextHandler() : null} className="mx-2">
              <Forward className={Style.controlBtn} style={{ opacity: canNext ? 1 : 0.5 }} />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }} onClick={forwardHandler}>
              <Next className={`${Style.controlBtn} ms-4`}  />
            </Box>
          </div>
        </div>
        <div className={`d-flex flex-column justify-content-center ${Style.waveSurferBox}`}>
          <div className={`${Style.waveSurferContainer}`}>
            <div id="player-wrap" className="w-100">
              <div
                style={{ pointerEvents: "none" }}
                id="player"
                className={`${!isPlaying ? Style.hidePlayer : ""}`}
              ></div>
              {!isPlaying && (
                <LinearProgress
                  color="inherit"
                  className={Style.linearProgress}
                  variant={ isLoading ? "indeterminate" : "buffer" }
                  value={100}
                />
              )}
            </div>
            <div className={`d-flex justify-content-between`}>
              <span className={`${Style.time}`}>{currentTime}</span>
              <span className={`${Style.time}`}>{trackDuration}</span>
            </div>
          </div>
        </div>
      </div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box className={`d-flex flex-column align-items-end ${Style.rightPortion}`}>
          <div className="d-flex">
            <div className="d-flex justify-content-center align-items-start">
              <Tooltip title="Add new playlist" arrow placement="top">
                <Playlist
                  className={Style.playList}
                  onClick={() => favoriteAndNewPlaylistHandler(false)}
                />
              </Tooltip>
            </div>
            <div className="d-flex justify-content-center align-items-start">
              <Loop
                className={Style.playList}
                style={{
                  opacity: isLoopTrack ? 1 : 0.5,
                  marginRight: 5,
                  cursor: "pointer",
                }}
                onClick={loopToggleHandler}
              />
            </div>
          </div>
          <div className="d-flex">
            <Speaker
              style={{
                opacity: isMuted ? 0.5 : 1,
                marginRight: 5,
                cursor: "pointer",
              }}
              onClick={muteToggleHandler}
            />
            <input type="range" className={Style.slider} min="0" max="1" value={volume} onChange={inputVolumeHandler} step="0.05" />
          </div>
        </Box>
      </Box>
    </>
  )
}

export default AnyDevice;